// Header
#header {
    position: absolute;
    z-index: 500;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    .logo {
        position: absolute;
        top: 0;
        z-index: 5;
        float: left;
        margin-bottom: -22px;
        padding: 15px 20px;
        width: 260px;
        background-color: #fff;
        .transition(all .2s);
        @media (max-width: @screen-sm-max) {
            position: relative;
            z-index: 0;
            top: auto;
            margin: 0;
        }
        @media (max-width: @screen-xs-max) {
            margin: 15px 0;
            padding: 0;
            background-color: transparent;
            width: 180px;
        }
    }
    #nav {
        margin-top: 108px;
        border-top: 1px solid #e5e5e5;
        .mainNav {
            float: left;
            @media(max-width: @screen-sm-max) {
                float: none;
            }
            > ul {
                margin: 0;
                padding: 0;
                list-style: none;
                @media(max-width: @screen-sm-max) {
                    float: none;
                }
                > li {
                    float: left;
                    .transition(all .2s);
                    @media(max-width: @screen-sm-max) {
                        float: none;
                    }
                    > a, > .nolink {
                        display: block;
                        margin: 0;
                        padding: 30px 0 27px;
                        background-color: transparent;
                        border-bottom: 3px solid transparent;
                        color: #83847a;
                        font-family: @ff-headline;
                        font-size: 0.88rem;
                        font-weight: 400;
                        letter-spacing: 1px;
                        line-height: 1.43em;
                        text-transform: uppercase;
                        .transition(all .2s);
                        .caret {
                            display: none;
                        }
                        @media(max-width: @screen-sm-max) {
                            padding: 15px 0;
                            border-bottom: 0;
                            color: #fff;
                        }
                    }
                    & + li {
                        margin-left: 45px;
                        @media(max-width: @screen-sm-max) {
                            margin-left: 0;
                        }
                    }
                    &:hover, &.active, &.active-trail, &.open {
                        > a, > .nolink {
                            border-color: @brand-primary;
                            color: @brand-primary;
                            @media(max-width: @screen-sm-max) {
                                color: #fff;
                                text-decoration: underline;
                            }
                        }
                    }
                    ul.dropdown-menu {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        float: none;
                        margin: 0;
                        padding: 0;
                        background-color: #fff;
                        border: 0;
                        border-radius: 0;
                        font-size: 0;
                        list-style: none;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                        @media(max-width: @screen-sm-max) {
                            position: relative;
                            top: auto;
                            left: auto;
                            text-align: center;
                            background-color: transparent;
                        }
                        > li {
                            > a, > .nolink {
                                display: block;
                                position: relative;
                                margin: 0;
                                padding: 10px 15px;
                                background-color: transparent;
                                color: @brand-primary;
                                font-family: @ff-headline;
                                font-size: 0.88rem;
                                font-weight: 700;
                                letter-spacing: 1px;
                                line-height: 1.43em;
                                text-transform: uppercase;
                                white-space: nowrap;
                                .transition(all .2s);
                                .caret {
                                    position: absolute;
                                    top: 50%;
                                    right: 5px;
                                    margin: -4px 0 0;
                                    height: 0;
                                    width: 0;
                                    border-top: 4px solid transparent;
                                    border-bottom: 4px solid transparent;
                                    border-left: 4px solid @brand-primary;
                                    border-right: 0;
                                    .transition(all .2s);
                                }
                                @media(max-width: @screen-sm-max) {
                                    color: #fff;
                                    white-space: normal;
                                    .caret {
                                        display: none;
                                    }
                                }
                            }
                            &:hover, &.active, &.active-trail, &.open {
                                > a, > .nolink {
                                    background-color: @brand-primary;
                                    color: #fff;
                                    .caret {
                                        border-left-color: #fff;
                                    }
                                }
                            }
                            ul.dropdown-menu {
                                top: 0;
                                left: 100%;
                                @media(max-width: @screen-sm-max) {
                                    top: auto;
                                    left: auto;
                                }
                                > li {
                                    > a, > .nolink {
                                        padding: 5px 20px;
                                        color: darken(#83847a, 30%);
                                        font-weight: 400;
                                        text-transform: none;
                                        .caret {
                                            border-left-color: #83847a;
                                        }
                                        @media(max-width: @screen-sm-max) {
                                            color: #fff;
                                            padding-top: 10px;
                                            padding-bottom: 10px;
                                            .caret {
                                                border-left-color: #fff;
                                            }
                                        }
                                    }
                                    &:hover, &.active, &.active-trail, &.open {
                                        > a, > .nolink {
                                            background-color: transparent;
                                            color: @brand-primary;
                                            .caret {
                                                border-left-color: @brand-primary;
                                            }
                                            @media(max-width: @screen-sm-max) {
                                                color: #fff;
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                    ul.dropdown-menu {
                                        > li {
                                            > a, > .nolink {
                                                color: #83847a;
                                                padding: 0 25px;
                                                letter-spacing: 0;
                                                @media(max-width: @screen-sm-max) {
                                                    padding-top: 10px;
                                                    padding-bottom: 10px;
                                                    color: #fff;
                                                }
                                            }
                                            &:hover, &.active, &.active-trail, &.open {
                                                > a, > .nolink {
                                                    color: @brand-primary;
                                                    @media(max-width: @screen-sm-max) {
                                                        color: #fff;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    > ul.dropdown-menu { 
                        @media(max-width: @screen-sm-max) {
                            border-top: 1px solid #e5e5e5;
                            padding-bottom: 30px;
                            ul.dropdown-menu {
                                display: block;
                                position: relative;
                                top: auto;
                                left: auto;
                            }
                        }
                    }
                    &.mm {
                        position: static;
                        > ul.dropdown-menu {
                            border-top: 1px solid #e5e5e5;
                            padding-bottom: 30px;
                            width: 100%;
                            > li {
                                display: inline-block;
                                vertical-align: top;
                                width: 25%;
                                @media(max-width: @screen-sm-max) {
                                    display: block;
                                    width: 100%;
                                }
                                > a, > .nolink {
                                    white-space: normal;
                                    .caret {
                                        display: none;
                                    }
                                }
                                > ul.dropdown-menu {
                                    display: block;
                                    position: relative;
                                    top: auto;
                                    left: auto;
                                    > li {
                                        > a, > .nolink {
                                            white-space: normal;
                                            .caret {
                                                margin: -2px 0 0;
                                                border-left: 4px solid transparent;
                                                border-right: 4px solid transparent;
                                                border-top: 4px solid #83847a;
                                            }
                                        }
                                        &:hover {
                                            .caret {
                                                border-top-color: @brand-primary;
                                            }
                                        }
                                        > ul.dropdown-menu {
                                            position: relative;
                                            top: auto;
                                            left: auto;
                                            @media(max-width: @screen-sm-max) {
                                                display: block;
                                            }
                                            > li {
                                                > a, > .nolink {
                                                    white-space: normal;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ul.dropdown-menu {
                li.expanded {
                    &:hover {
                        > ul.dropdown-menu {
                            display: block;
                        }
                    }
                }
            }
        }
        .topNav {
            float: left;
            margin: 20px 0;
            .transition(all .2s);
            @media (min-width: @screen-md-min) {
                opacity: 1;
                visibility: visible;
            }
            @media(max-width: @screen-sm-max) {
                float: none;
            }
            > ul {
                margin: 0;
                padding: 0;
                list-style: none;
                > li {
                    float: left;
                    @media(max-width: @screen-sm-max) {
                        float: none;
                    }
                    > a, > .nolink {
                        display: block;
                        margin: 0;
                        padding: 0;
                        background-color: transparent;
                        color: #83847a;
                        font-family: @ff-headline;
                        font-size: 0.88rem;
                        font-weight: 400;
                        line-height: 1.43em;
                        .transition(all .2s);
                        .caret {
                            display: none;
                        }
                        @media(max-width: @screen-sm-max) {
                            padding: 10px 0;
                            color: #fff;
                        }   
                    }
                    & + li {
                        margin-left: 20px;
                        @media(max-width: @screen-sm-max) {
                            margin-left: 0;
                        }
                    }
                    &:hover, &.active, &.active-trail, &.open {
                        > a, > .nolink {
                            color: @brand-primary;
                            @media(max-width: @screen-sm-max) {
                                color: #fff;
                                text-decoration: underline;
                            }
                        }
                    }
                    ul.dropdown-menu {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        float: none;
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        background-color: #fff;
                        border: 0;
                        border-radius: 0;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                        @media(max-width: @screen-sm-max) {
                            position: relative;
                            top: auto;
                            left: auto;
                            background-color: transparent;
                            text-align: center;
                        }
                        > li {
                            > a, > .nolink {
                                display: block;
                                margin: 0;
                                padding: 5px 10px;
                                background-color: transparent;
                                color: #83847a;
                                font-family: @ff-headline;
                                font-size: 0.88rem;
                                font-weight: 700;
                                line-height: 1.43em;
                                .transition(all .2s);
                                @media(max-width: @screen-sm-max) {
                                    padding: 10px 0;
                                    color: #fff;
                                }   
                            }
                            &:hover, &.active, &.active-trail, &.open {
                                > a, > .nolink {
                                    color: @brand-primary;
                                    @media(max-width: @screen-sm-max) {
                                        color: #fff;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .socials {
            float: left;
            margin-left: 35px;
            font-size: 0;
            @media(max-width: @screen-sm-max) {
                float: none;
                margin: 0;
            }
            a {
                display: inline-block;
                vertical-align: middle;
                margin: 30px 0;
                padding: 0;
                color: #cacac8;
                font-size: 1.25rem;
                line-height: 1em;
                text-decoration: none;
                .transition(all .2s);
                .fa {
                    display: block;
                }
                & + a {
                    margin-left: 20px;
                }
                &:hover {
                    color: @brand-primary;
                    @media(max-width: @screen-sm-max) {
                        color: #fff;
                    }
                }
                &.social-youtube {
                    display: none;
                }
            }
        }
        #lang {
            position: relative;
            float: left;
            margin: 20px 0;
            margin-left: 20px;
            font-family: @ff-headline;
            font-size: 0.88rem;
            font-weight: 700;
            line-height: 1.43em;
            .transition(all .2s);
            @media (min-width: @screen-md-min) {
                opacity: 1;
                visibility: visible;
            }
            @media(max-width: @screen-sm-max) {
                float: none;
                margin-left: 0;
            }
            .label {
                color: #83847a;
                cursor: pointer;
                .transition(all .2s);
                @media(max-width: @screen-sm-max) {
                    display: inline-block;
                    vertical-align: middle;
                    color: #fff;
                    text-decoration: underline;
                }
                .fa {
                    margin-left: 5px;
                     @media(max-width: @screen-sm-max) {
                        display: none;
                    }
                }
                &:hover {
                    color: @brand-primary;
                    @media(max-width: @screen-sm-max) {
                        color: #fff;
                    }
                }
            }
            ul {
                display: none;
                position: absolute;
                top: 100%;
                left: 0;
                margin: 0;
                padding: 0;
                list-style: none;
                @media(max-width: @screen-sm-max) {
                    position: relative;
                    top: auto;
                    left: auto;
                    display: inline-block !important;
                    vertical-align: middle;
                }
                li {
                    @media(max-width: @screen-sm-max) {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 10px;
                    }
                    a {
                        display: block;
                        color: #83847a;
                        text-decoration: none;
                        .transition(all .2s);
                        @media(max-width: @screen-sm-max) {
                            color: #fff;
                        }
                        &:hover {
                            color: @brand-primary;
                            @media(max-width: @screen-sm-max) {
                                color: #fff;
                                text-decoration: underline;
                            }
                        }
                    }
                }
                &.expand {
                    display: block;
                }
            }
        }
        .coord {
            float: left;
            margin-left: 30px;
            padding-left: 25px;
            border-left: 1px solid #e5e5e5;
            .transition(all .2s);
            @media (min-width: @screen-md-min) {
                opacity: 1;
                visibility: visible;
            }
            @media(max-width: @screen-sm-max) {
                float: none;
                margin: 20px 0;
                padding-left: 0;
                border: 0;
            }
            a {
                display: block;
                padding: 5px 0;
                color: #83847a;
                font-family: @ff-headline;
                font-size: 0.88rem;
                font-weight: 400;
                line-height: 1em;
                text-decoration: none;
                .transition(all .2s);
                @media(max-width: @screen-sm-max) {
                    padding: 10px 0;
                    color: #fff;
                }
                .fa {
                    margin-right: 25px;
                    font-size: 1.25rem;
                }
                &:hover {
                    color: @brand-primary;
                    @media(max-width: @screen-sm-max) {
                        color: #fff;
                        text-decoration: underline;
                    }
                }
            }
        }
        .searchWrapper {
            position: relative;
            float: left;
            margin-left: 30px;
            padding-left: 25px;
            border-left: 1px solid #e5e5e5;
            @media(max-width: @screen-sm-max) {
                float: none;
                margin: 20px 0;
                padding-left: 0;
                border: 0;
            }
            > a {
                display: block;
                margin: 16px 0;
                font-size: 1.75rem;
                line-height: 1em;
                text-decoration: none;
                .transition(all .2s);
                @media(max-width: @screen-sm-max) {
                    display: none;
                }
                .fa {
                    display: block;
                }
            }
            #searchBox {
                position: absolute;
                top: 0;
                right: 0;
                width: 0;
                overflow: hidden;
                .transition(all .35s);
                @media(max-width: @screen-sm-max) {
                    position: relative;
                    top: auto;
                    right: auto;
                    width: auto;
                }
                form {
                    font-size: 0;
                    padding: 5px 0;
                    background-color: #fff;
                    border-left: 1px solid #e5e5e5;
                    width: 100%;
                    white-space: nowrap;
                    @media(max-width: @screen-sm-max) {
                        padding: 0;
                        background-color: transparent;
                        border: 0;
                    }
                    .element-invisible, .form-actions {
                        display: none;
                    }
                    .form-control {
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0;
                        padding: 0 20px;
                        height: 50px;
                        width: 75%;
                        background-color: #fff;
                        border: 0;
                        border-radius: 4px 0 0 4px;
                        color: #b1b2ac;
                        font-family: @ff-headline;
                        font-size: 1rem;
                        font-style: italic;
                        font-weight: 400;
                        line-height: 1em;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                    }
                    .btn {
                        display: inline-block;
                        vertical-align: middle;
                        padding: 0 20px;
                        height: 50px;
                        width: 25%;
                        background-color: lighten(@brand-primary, 10%);
                        border: 0;
                        border-radius: 0 4px 4px 0;
                        font-size: 1rem;
                        line-height: 1em;
                        white-space: normal;
                        &:hover {
                            background-color: darken(@brand-primary, 5%);
                        }
                    }
                }
                &.open {
                    width: 400px;
                }
            }
        }
        .main {
            .clearfix;
            .wrapper {
                position: relative;
                float: right;
                @media(max-width: @screen-sm-max) {
                    float: none;
                }
            }
        }
        .top {
            position: absolute;
            top: 0;
            left: 0;
            margin: 25px 0;
            width: 100%;
            @media(max-width: @screen-sm-max) {
                position: relative;
                top: auto;
                left: auto;
                margin: 0;
            }
            .wrapper {
                float: right;
                @media(max-width: @screen-sm-max) {
                    float: none;
                }
            }
        }
    }
    &.scrolled {
        position: fixed;
        top: -110px;
        @media (max-width: @screen-sm-max) {
            top: 0;
        }
        .logo {
            top: 110px;
            padding: 5px;
            width: 160px;
            @media (max-width: @screen-sm-max) {
                top: auto;
            }
            @media (max-width: @screen-xs-max) {
                margin: 5px 0;
                padding: 0;
                width: 150px;
            }
        }
        #nav {
            .mainNav {
                > ul {
                    > li {
                        & + li {
                            margin-left: 30px;
                            @media (max-width: @screen-sm-max) {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: @screen-md-min) {
    body.searchOpen {
        #header {
            #nav {
                .topNav, #lang, .coord {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
}

#head-title {
    padding-top: 30px;
    padding-bottom: 85px;
    background-color: fade(@brand-primary, 20%);
    @media (max-width: @screen-xs-max) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    h1 {
        margin: 0;
        color: #83847a;
        font-size: 1.63rem;
        line-height: normal;
        span {
            color: #979890;
            font-size: 1rem;
        }
    }
    &.pi {
        padding-bottom: 105px;
        @media (max-width: @screen-xs-max) {
            padding-bottom: 60px;
        }
        h1 {
            font-size: 1.88rem;
            line-height: 1.17em;
        }
    }
}

#leaderboard {
    padding-top: 1px;
    padding-bottom: 200px;
    background-color: lighten(@brand-primary, 10%);
    .container {
        margin-top: -60px;
        -webkit-animation-delay: .25s;
        -moz-animation-delay: .25s;
        animation-delay: .25s;
        @media (max-width: @screen-xs-max) {
            margin-top: 0;
        }
    }
    .cycle-slideshow {
        height: 480px;
        overflow: hidden;
        @media(max-width: @screen-sm-max) {
            height: 700px;
        }
        @media(max-width: @screen-xs-max) {
            height: 780px;
        }
        @media(max-width: 479px) {
            height: 680px;
        }
    }
    .slide {
        height: 480px;
        color: #fff;
        @media(max-width: @screen-sm-max) {
            height: 700px;
        }
        @media(max-width: @screen-xs-max) {
            height: 780px;
        }
        @media(max-width: 479px) {
            height: 680px;
        }
        &--img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            float: left;
            width: 65%;
            @media(max-width: @screen-sm-max) {
                position: relative;
                top: auto;
                bottom: auto;
                left: auto;
                float: none;
                padding-bottom: 56.25%;
                height: 0;
                width: 100%;
                overflow: hidden;
            }
        }
        &--content {
            position: relative;
            float: left;
            margin-top: 60px;
            margin-left: 65%;
            padding: 40px 0 50px 50px;
            width: 35%;
            @media(max-width: @screen-md-max) {
                padding: 25px 0 25px 25px;
            }
            @media(max-width: @screen-sm-max) {
                float: none;
                margin: 0;
                padding: 30px;
                width: 100%;
            }
            @media (max-width: @screen-xs-max) {
                padding: 30px 15px 60px;
            }
            h2 {
                margin: 0 0 20px;
                color: #fff;
            }
            p {
                opacity: .6;
            }
            .btn {
                .fa {
                    margin-left: 30px;
                }
            }
        }
    }
    .controls {
        position: absolute;
        z-index: 10;
        bottom: 50px;
        right: 0;
        color: #fff;
        font-size: 2.5rem;
        line-height: 1em;
        @media (max-width: @screen-xs-max) {
            bottom: 15px;
            right: auto;
            left: 15px;
        }
        .nextControl {
            margin-left: 30px;
        }
        > span {
            display: block;
            float: left;
            cursor: pointer;
            opacity: .35;
            .transition(all .2s);
            &:hover {
                opacity: 1;
            }
        }
    }
}

#banner {
    position: relative;
    height: 330px;
    background-color: #000;
    @media (max-width: @screen-xs-max) {
        height: 300px;
    }
    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: -moz-linear-gradient(20deg,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(20deg,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
        background: linear-gradient(20deg,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 );
    }
    .title {
        position: absolute;
        z-index: 5;
        bottom: 80px;
        left: 0;
        width: 100%;
        @media (max-width: @screen-xs-max) {
            bottom: 15px;
        }
        h1 {
            margin: 0;
            color: #fff;
        }
    }
    #block-views-banners-block-banners.contextual-links-region {
        position: static;
    }
}
body.page-actualites, body.page-evenements {
    #banner {
        height: 225px;
        .title {
            bottom: 60px;
        }
    }
}

.cta-fixed {
    position: fixed;
    z-index: 200;
    right: 0;
    .transition(all .2s);
    a {
        padding: 10px 15px;
        width: 180px;
        border: 0;
        border-radius: 0;
        font-size: 0;
        text-align: left;
        svg, .fa, span {
            display: inline-block;
            vertical-align: middle;
        }
        .fa {
            font-size: 2rem;
            width: 32px;
            text-align: center;
        }
        span {
            margin-left: 20px;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1em;
        }
    }
    &#webradio {
        top: 190px;
        @media (max-width: @screen-xs-max) {
            top: 110px;
        }
    }
    &#infoline {
        top: 242px;
        a {
            background-color: #7db37d;
            &:hover {
                background-color: darken(#7db37d, 15%);
            }
        }
        @media (max-width: @screen-xs-max) {
            top: 162px;
        }
    }
    &.scrolled {
        right: -118px;
        &:hover {
            right: 0;
        }
    }
}
body.front {
    #webradio {
        top: 330px;
        @media (max-width: @screen-xs-max) {
            top: 110px;
        }
    }
    #infoline {
        top: 382px;
        @media (max-width: @screen-xs-max) {
            top: 162px;
        }
    }
}

.trigger-menu {
    position: relative;
    z-index: 15;
    float: right;
    margin: 15px 20px;
    @media (max-width: @screen-xs-max) {
        margin: 15px 0;
    }
    svg {
    	display: block;
        fill: @brand-primary !important;
    	rect {
    		-webkit-transform-origin: left center;
    		-moz-transform-origin: left center;
    		-ms-transform-origin: left center;
    		-o-transform-origin: left center;
    		transform-origin: left center;
    		-webkit-transform: rotate(0);
    		-moz-transform: rotate(0);
    		-ms-transform: rotate(0);
    		-o-transform: rotate(0);
    		transform: rotate(0);
    		.transition(all .2s);
    	}
    }
    &.open {
    	svg {
    		rect {
                fill: #fff !important;
    			&:nth-child(1) {
    				-webkit-transform: rotate(45deg);
    				-moz-transform: rotate(45deg);
    				-ms-transform: rotate(45deg);
    				-o-transform: rotate(45deg);
    				transform: rotate(45deg);
    				x: 3px;
    			}
    			&:nth-child(2) {
    				width: 0%;
    				opacity: 0;
    			}
    			&:nth-child(3) {
    				-webkit-transform: rotate-(45deg);
    				-moz-transform: rotate(-45deg);
    				-ms-transform: rotate(-45deg);
    				-o-transform: rotate(-45deg);
    				transform: rotate(-45deg);
    				x: 3px;
    			}
    		}
    	}
    }
}

@media (max-width: @screen-sm-max) {
    nav#nav {
        position: fixed;
        z-index: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0 !important;
        padding: 60px 0;
        border: 0 !important;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        background-color: fade(#83847a,90%);
        overflow-x: auto;
        overflow-y: scroll;
        .transition(all .35s);
    }
}

body.open-menu {
    @media (max-width: @screen-sm-max) {
        height: 100%;
        overflow: hidden;
        nav#nav {
            visibility: visible;
            opacity: 1;
        }
    }
}

body.admin-menu.adminimal-menu {
    #header, .cta-fixed {
        margin-top: 29px;
    }
}