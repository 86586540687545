// Footer
.actusWrapper {
	.nav-actus {
		a {
			display: block;
			float: left;
			padding: 25px 15px;
			width: 50%;
			background-color: fade(#4d4e45, 10%);
			color: @brand-primary;
			font-family: @ff-headline;
			font-size: 1.5rem;
			font-weight: 400;
			line-height: 1.25em;
			text-align: center;
			text-decoration: none;
			text-transform: normal;
			@media (max-width: @screen-xs-max) {
				padding: 15px;
				width: 100%;
			}
			&:hover, &.active {
				background-color: @brand-primary;
				color: #fff;
			}
		}
	}
	.actus {
		padding-top: 100px;
		padding-bottom: 80px;
		@media (max-width: @screen-xs-max) {
			padding-top: 60px;
			padding-bottom: 60px;
		}
	}
	&#evenements_passes {
		background-color: #fff;
	}
	.title {
		margin: 0 0 45px;
		font-size: 1rem;
		letter-spacing: .75px;
		line-height: 1em;
		text-transform: uppercase;
	}
	.text-center {
		text-align: left;
	}
	.pagination {
		margin-top: 80px;
	}
}