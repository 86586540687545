body.node-type-page-de-contenu-avec-menu {
	#main {
		position: relative;
		z-index: 10;
	}
}

#page-tabs {
	position: relative;
	.header-tabs {
		position: absolute;
		bottom: 100%;
		width: 100%;
		background-color: #fff;
		@media (max-width: @screen-xs-max) {
			position: relative;
			bottom: auto;
		}
	}
	.title-wrapper {
		position: relative;
		h1 {
			position: absolute;
			top: 50%;
			left: 41.66666667%;
			margin: 0;
			padding: 30px 90px;
			width: 58.33333333%;
			color: #951b3e;
			font-size: 1.88rem;
			text-align: right;
			@media (min-width: @screen-sm-min) {
				-webkit-transform: translate(0,-50%);
				-moz-transform: translate(0,-50%);
				-ms-transform: translate(0,-50%);
				-o-transform: translate(0,-50%);
				transform: translate(0,-50%);

			}
			@media (max-width: @screen-md-max) {
				left: 33.33333333%;
				padding: 30px 60px;
    			width: 66.66666667%;
    		}
			@media (max-width: @screen-sm-max) {
				left: 30%;
				padding: 30px 15px;
    			width: 70%;
    		}
    		@media (max-width: @screen-xs-max) {
				position: relative;
				top: auto;
				left: auto;
				padding: 15px;
				width: 100%;
				text-align: left;
			}
		}
		.img-wrapper {
			padding: 15px;
			width: 41.66666667%;
			@media (max-width: @screen-md-max) {
    			width: 33.33333333%;
    		}
			@media (max-width: @screen-sm-max) {
    			width: 30%;
    		}
    		@media (max-width: @screen-xs-max) {
    			width: 100%;
    		}
			img {
				// margin: 0 auto;
				max-height: 160px;
			}
		}
		&.titleOnly {
			h1 {
				position: relative;
				top: auto;
				left: auto;
				width: 100%;
				-webkit-transform: translate(0,0);
				-moz-transform: translate(0,0);
				-ms-transform: translate(0,0);
				-o-transform: translate(0,0);
				transform: translate(0,0);
			}
		}
	}
	.nav-tabs {
		margin: 0;
		padding: 0;
		border: 0;
    	list-style: none;
    	> li {
    		display: block;
    		float: left;
    		margin: 0;
    		width: 33.33333333%;
    		@media (max-width: @screen-sm-max) {
    			width: 100%;
    		}
    		& + li {
    			padding-left: 1px;
    			@media (max-width: @screen-sm-max) {
	    			padding-left: 0;
	    			margin-top: 1px;
	    		}
    		}
    		> a {
    			display: block;
    			margin: 0;
    			padding: 25px 15px;
    			background-color: #ededec;
    			border: 0;
    			border-radius: 0;
    			color: #949494;
    			font-family: @ff-headline;
    			font-size: 1.5rem;
    			font-weight: 400;
    			line-height: 1.25em;
    			text-align: center;
    			.transition(all .2s);
    			@media (max-width: @screen-sm-max) {
	    			padding-top: 10px;
	    			padding-bottom: 10px;
	    		}
    		}
    		&.active, &:hover {
    			> a {
    				background-color: #951b3e;
    				color: #fff;
    			}
    		}
    	}
	}

	.content-body {
		h2, p.lead, a:not(.btn), ul:not(.tabs--primary) li:before {
			color: #951b3e;
		}
		p.evidence, div.evidence {
			background-color: #951b3e;
		}
		.btn1 {
			background-color: #951b3e;
			border-color: #951b3e;
			&:hover {
				background-color: darken(#951b3e, 15%);
				border-color: darken(#951b3e, 15%);
			}
		}
		.btn2 {
			border-color: #951b3e;
			color: #951b3e;
			&:hover {
				background-color: #951b3e;
				color: #fff;
			}
		}
	}

	.panel {
		& + .panel {
			border-top: solid 1px #951b3e;
		}
	}
	.panel-heading {
		.panel-title {
			margin: 0;
			a {
				display: block;
				position: relative;
				padding: 20px 15px;
				padding-right: 45px;
				color: #951b3e;
				text-decoration: none;
				.transition(all .2s);
				.fa {
					position: absolute;
					top: 50%;
					right: 15px;
					margin-top: -15px;
					font-size: 30px;
					line-height: 1em;
				}
				&:not(.collapsed), &:hover {
					background-color: #951b3e;
					color: #fff;
				}
				&:not(.collapsed) {
					.fa {
						&:before {
							    content: "\f106";
						}
					}
				}
			}
		}
	}
	.panel-collapse {
		.panel-body {
			padding: 20px 15px;
		}
	}

	&.ctb {
		h1 {
			color: darken(#c3d8b7, 15%);
		}
		.nav-tabs {
	    	> li {
	    		&.active, &:hover {
	    			> a {
	    				background-color: darken(#c3d8b7, 15%);
	    			}
	    		}
	    	}
		}
		.content-body {
			h2, p.lead, a:not(.btn), ul:not(.tabs--primary) li:before {
				color: darken(#c3d8b7, 15%);
			}
			p.evidence, div.evidence {
				background-color: darken(#c3d8b7, 15%);
			}
			.btn1 {
				background-color: darken(#c3d8b7, 15%);
				border-color: darken(#c3d8b7, 15%);
				&:hover {
					background-color: darken(#c3d8b7, 30%);
					border-color: darken(#c3d8b7, 30%);
				}
			}
			.btn2 {
				border-color: darken(#c3d8b7, 15%);
				color: darken(#c3d8b7, 15%);
				&:hover {
					background-color: darken(#c3d8b7, 15%);
					color: #fff;
				}
			}
		}
		.panel {
			& + .panel {
				border-color: darken(#c3d8b7, 15%);
			}
		}
		.panel-heading {
			.panel-title {
				a {
					color: darken(#c3d8b7, 15%);
					&:not(.collapsed), &:hover {
						background-color: darken(#c3d8b7, 15%);
						color: #fff;
					}
				}
			}
		}
	}
}