.view-display-id-annuaire_des_mosquees {
  > .view-content {
    padding-top: 60px;
    padding-bottom: 120px;
    @media (max-width: @screen-xs-max) {
      padding-bottom: 60px;
    }
  }
  #ip-geoloc-map-of-view-annuaire_des_mosquees-attachment_1 {
    top: -40px;
    width: 100%;
    @media (max-width: @screen-sm-max) {
      height: 400px !important;
    }
    @media (max-width: @screen-xs-max) {
      height: 300px !important;
    }
  }
}
