// Footer
#footer {
	padding-top: 100px;
	padding-bottom: 30px;
	background-color: #4d4e44;
	@media(max-width: @screen-xs-max) {
		padding-top: 30px;
	}
	h3 {
		margin: 0 0 20px;
		color: #fff;
		font-size: 1.13rem;
		font-weight: 700;
	}
	p, ul, ol {
		margin-bottom: 20px; 
		color: #95968d;
		font-family: @ff-headline;
		line-height: 1.5em;
		&:last-child {
			margin-bottom: 0;
		}
	}
	a {
		color: #fff;
	}
	.horaire {
		@media(max-width: @screen-xs-max) {
			margin-top: 30px;
		}
	}
	.menu {
		@media(max-width: @screen-sm-max) {
			margin-top: 30px;
		}
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			@media(min-width: @screen-sm-min) {
				-webkit-column-count: 2;
			    -moz-column-count: 2;
			    column-count: 2;
			}
			li {
				display: inline-block;
				margin-bottom: 10px;
				width: 100%;
				a, .nolink {
					padding: 0;
					background-color: transparent;
					color: #fff;
					text-transform: uppercase;
				}
				&:hover, &.active {
					a, .nolink {
						text-decoration: underline;
					}
				}
			}
		}
	}
	.copyright {
		padding-top: 60px;
		text-align: center;
	}
}