body:not(.cke_editable) {
	padding-top: 190px;
	@media(max-width: @screen-sm-max) {
		padding-top: 130px;
	}
	@media(max-width: @screen-xs-max) {
		padding-top: 110px;
	}
}

.container {
	&.md-width {
		max-width: 960px;
	}
}

.v-align {
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translate(0,-50%);
	-moz-transform: translate(0,-50%);
	-ms-transform: translate(0,-50%);
	-o-transform: translate(0,-50%);
	transform: translate(0,-50%);
}
.same-height--md {
	@media (min-width: @screen-md-min) {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}
}
.same-height--sm {
	@media (min-width: @screen-sm-min) {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}
}
.no-gap {
	padding-left: 0;
	padding-right: 0;
}
.no-marge {
	margin-left: 0;
	margin-right: 0;
}
.bg {
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

// Animate
.animated {
	.animated;
}
.os-animation {
	opacity: 0;
	@media (max-width: @screen-xs-max) {
		-webkit-animation-delay: 0s !important;
		-moz-animation-delay: 0s !important;
		-ms-animation-delay: 0s !important;
		-o-animation-delay: 0s !important;
		animation-delay: 0s !important;
	}
}

// Content Body
.content-body {
	position: relative;
	max-width: 1170px;
	margin: 0 auto;
	padding-top: 100px;
	padding-bottom: 60px;
	@media (max-width: @screen-xs-max) {
		padding-top: 60px;
	}

	// All
	& > * {
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
	}
	
	// Aside
	& > aside {
		max-width: 1170px;
	}

	// Colonnes
	& > .row {
		margin-bottom: @line-height-computed;

		 .col-md-6:first-child {
			padding-left: 0;
			@media (max-width: @screen-sm-max) {
				padding-right: 0;
			}
		}

		 .col-md-6:last-child {
			padding-right: 0;
			@media (max-width: @screen-sm-max) {
				padding-left: 0;
			}
		}
	}

	ul:not(.tabs--primary) {
		.customList;
	}
}

// Types
p:not(:last-child) {
	margin-bottom: 25px;
}
h1, .h1 {
	@media (max-width: @screen-xs-max) {
		font-size: 1.9rem;
	}
}
h2, .h2 {
	margin-top: 30px;
	margin-bottom: 30px;
}
h4,.h4 {
	margin-top: 20px;
	margin-bottom: 20px;
	color: @brand-primary;
	font-weight: 700;
	text-transform: uppercase;
}
.customList {
	padding: 0;
	list-style: none;
	li {
		position: relative;
		padding-left: 20px;
		&:before {
			content: '\f105';
			position: absolute;
			color: @brand-primary;
			top: 0;
			left: 0;
			font-family: 'FontAwesome';
			font-size: 1em;
		}
	}
}

// Lead
p.lead {
	margin-bottom: @line-height-computed;
	color: #b7b7b7;
	font-family: @ff-headline;
	font-size: 2rem;
	line-height: 1.41em;
	font-style: normal;
	font-weight: 400;
	text-transform: none;
}

// Evidence
p.evidence, div.evidence {
	color: #fff;
	background-color: @brand-primary;
	padding: @line-height-computed*2;
	margin-bottom: @line-height-computed;
	@media (max-width: @screen-sm-max) {
		padding: 30px;
	}
	@media (max-width: @screen-xs-max) {
		padding: 15px;
	}

	a:not(.btn) {
		color: #fff;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}

	a.btn {
		background-color: #fff;
		color: @brand-primary;

		&:hover {
			background-color: darken(#fff, 10%);
		}
	}
}

// Img
img {
	max-width: 100%;
	height: auto;

	&[style*="left"] {
		margin-right: @line-height-computed;
		margin-bottom: @line-height-computed;
	}

	&[style*="right"] {
		margin-left: @line-height-computed;
		margin-bottom: @line-height-computed;
	}
}

// Table
table {
	border: 1px solid #d8d8d8;
	margin-bottom: @line-height-computed;
	width: 100%;

	th {
		background-color: @brand-primary;
		color: #fff;
		text-transform: uppercase;
		font-weight: 400;
	}

	td {
		background-color: #f0f0f0;
	}

	tr.even td {
		background-color: darken(#f0f0f0, 5%);
	}

	td, th {
		padding: @line-height-computed/2 @line-height-computed;
	}
}

// Iframe responsive
.iframe-wrapper {
	max-width: 700px;
}
.iframe-embed {
	display: block;
	position: relative;
	margin-bottom: @line-height-computed;
	padding: 0;
	padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

	iframe {
		position: absolute;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    border: 0;
	}
}

// Boutons
.btn {
    margin: 0;
    padding: 15px 30px;
    background-color: transparent;
    border: 2px solid @brand-primary;
    border-radius: 4px;
    color: @brand-primary;
    font-family: @ff-headline;
    font-size: 1.13rem;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    white-space: normal;
    .transition(all .35s);
    &:focus {
    	outline: 0;
    }
    &.btn-primary, &.btn-default, &.btn1 {
    	background-color: @brand-primary;
    	color: #fff;
    	&:hover {
    		background-color: darken(@brand-primary, 15%);
    		border-color: darken(@brand-primary, 15%);
    	}
    }
    &.btn2 {
    	background-color: transparent;
    	color: @brand-primary;
    	&:hover {
    		background-color: @brand-primary;
    		border-color: @brand-primary;
    		color: #fff;
    	}
    }
    &.btn-border {
    	border: 2px solid #fff;
    	color: #fff;
    	&:hover {
    		background-color: #fff;
    		color: @brand-primary;
    	}
    }
}


// Service
.service {
	padding: 50px 60px;
	background-color: #fff;
	@media(max-width: @screen-md-max) {
        padding: 30px 40px;
    }
    @media(max-width: @screen-xs-max) {
        padding: 30px 15px;
    }
	h3 {
		.h4;
		margin-top: 0;
	}
	ul, ol, p {
		color: #898a81;
		font-size: 1.13rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	a {
		color: #898a81;
		text-decoration: none;
		&:hover {
			color: @brand-primary;
		}
	}
	ul, ol {
		li {
			& +li {
				margin-top: 10px;
			}
		}
	}
	ul {
		.customList;
	}
	& + .service {
		border-left: 1px solid fade(#83847a, 30%);
		@media(max-width: @screen-sm-max) {
	        border-left: 0;
	        border-top: 1px solid fade(#83847a, 30%);
	    }
	}
}

// News
.news {
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: #4d4e44;
	text-decoration: none;
	figure {
		position: relative;
		overflow: hidden;
		&:after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 40%;
			right: 0;
			bottom: 0;
			left: 0;
			background: -moz-linear-gradient(top,  rgba(17,16,10,0) 0%, rgba(17,16,10,1) 100%);
			background: -webkit-linear-gradient(top,  rgba(17,16,10,0) 0%,rgba(17,16,10,1) 100%);
			background: linear-gradient(to bottom,  rgba(17,16,10,0) 0%,rgba(17,16,10,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0011100a', endColorstr='#11100a',GradientType=0 );
			opacity: .7;
		}
		img {
			-webkit-transform: scale(1.0);
			-moz-transform: scale(1.0);
			-ms-transform: scale(1.0);
			-o-transform: scale(1.0);
			transform: scale(1.0);
			.transition(all .35s);
		}
		figcaption {
			position: absolute;
			z-index: 5;
			bottom: 0;
			left: 0;
			padding: 20px 30px;
			width: 100%;
			color: #fff;
			font-family: @ff-headline;
			font-size: 0.88rem;
			line-height: 1.5em;
			text-transform: uppercase;
			span {
				letter-spacing: 1.5px;
			}
			.date {
				display: inline-block;
				vertical-align: middle;
				margin-right: 25px;
				padding: 7px 8px;
				min-width: 60px;
				background-color: @brand-primary;
				text-align: center;
				.jour {
					font-size: 1.71em;
					font-weight: 700;
					line-height: 1em;
				}
			}
		}
	}
	&--event {
		figure {
			figcaption {
				padding-bottom: 0;
				span {
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}
	.title {
		background-color: #4d4e44;
		padding: 15px 30px;
		min-height: 100px;
		.transition(all .35s);
		h3 {
			.h4;
			margin: 0;
			color: #fff;
			font-weight: 700;
			text-transform: none;
		}
	}
	&:focus {
		outline: 0;
	}
	&:hover {
		text-decoration: none;
		figure {
			img {
				-webkit-transform: scale(1.1);
				-moz-transform: scale(1.1);
				-ms-transform: scale(1.1);
				-o-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
		.title {
			background-color: @brand-primary;
		}
	}
}

.actu {
	.clearfix;
	display: block;
	position: relative;
	background-color: #fff;
	color: @text-color;
	text-decoration: none;
	.transition(all .35s);
	.image {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 50%;
		@media(max-width: @screen-xs-max) {
			position: relative;
			top: auto;
			bottom: auto;
			left: auto;
			padding-bottom: 56.25%;
			height: 0;
			width: 100%;
		}
	}
	.content {
		position: relative;
		padding: 60px;
		@media(max-width: @screen-sm-max) {
			padding: 30px;
		}
		@media(max-width: @screen-xs-max) {
			padding: 30px 15px;
		}
		h2 {
			margin: 0 0 15px;
			.transition(all .35s);
		}
		.date {
			margin: 0;
			color: #cacac8;
			.transition(all .35s);
		}
	}
	.blockDate {
		position: absolute;
		z-index: 1;
		top: 40px;
		right: 100%;
		display: inline-block;
		vertical-align: middle;
		padding: 7px 8px;
		min-width: 60px;
		background-color: @brand-primary;
		color: #fff;
		font-family: @ff-headline;
		font-size: 0.88rem;
		line-height: 1.5em;
		text-align: center;
		text-transform: uppercase;
		@media (max-width: @screen-xs-max) {
			padding: 15px;
			top: 0;
			left: 0;
			right: auto;
		}
		.jour {
			font-size: 1.71em;
			font-weight: 700;
			line-height: 1em;
		}
	}
	&.event {
		.content {
			@media(max-width: @screen-xs-max) {
				padding-top: 90px;
			}
		}
	}
	& + .actu {
		margin-top: 55px;
	}
	&:hover {
		background-color: @brand-primary;
		color: #fff;
		h2, .date {
			color: #fff;
		}
	}
	&.evenChange:nth-of-type(even) {
		.image {
			left: 50%;
			width: 50%;
			@media (max-width: @screen-xs-max) {
				left: auto;
				width: 100%;
			}
		}
		.content {
			margin-left: 0;
		}
		.blockDate {
			left: 100%;
			right: auto;
			@media (max-width: @screen-xs-max) {
				left: auto;
				right: 0;
			}
		}
	}
}

.view-more {
	display: block;
	color: #4d4e44;
	font-size: 0.88rem;
	font-weight: 400;
	letter-spacing: 1px;
	line-height: 1em;
	text-decoration: none;
	text-transform: uppercase;
	&:hover {
		color: @brand-primary;
		text-decoration: none;
	}
}

.pagination {
	display: inline-block;
	margin: 0;
	padding: 0;
    border-radius: 0;
    font-size: 0;
    li {
    	display: inline-block;
    	a, span {
    		display: block;
			float: none;
			margin: 0;
			padding: 0;
			width: 40px;
			background-color: fade(#737469, 20%);
			border: 0;
			color: #0c0e0a;
			font-family: @ff-headline;
			font-size: 0.88rem;
			font-weight: 400;
			line-height: 40px;
			text-align: center;
			text-decoration: none;
			.transition(all .2s);
			&:hover {
				background-color: #737469;
				color: #fff;
			}
    	}
    	&:first-child, &:last-child {
    		a, span {
				border-radius: 0;
    		}
    	}
    	&.active {
    		a, span {
				background-color: #737469;
				color: #fff;
				&:hover {
					background-color: #737469;
				}
    		}
    	}
    	& + li {
    		margin-left: 5px;
    	}
    }
}

.view-filters {
	position: relative;
	z-index: 10;
	margin-top: -60px;
	margin-bottom: -40px;
	padding: 0;
	background-color: #83847a;
	@media(max-width: @screen-xs-max) {
		margin-top: 0;
		margin-bottom: 0;
	}
	.views-exposed-widget{
		float: left;
		padding: 30px 35px;
		@media(max-width: @screen-xs-max) {
			float: none;
		}
		.form-item {
			margin: 0;
			font-size: 0;
			label {
				display: inline-block;
				vertical-align: middle;
				margin: 0 15px 0 0;
				color: rgba(255,255,255,0.5);
				font-family: @ff-headline;
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.25em;
			}
			.form-control {
				display: inline-block;
				height: 40px;
				width: auto;
				max-width: 200px;
				padding: 0 20px;
				background-color: transparent;
				border: 1px solid #fff;
				border-radius: 4px;
				color: #fff;
				font-family: @ff-headline;
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.25em;
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;
				@media(max-width: @screen-xs-max) {
					width: 100%;
					max-width: none;
				}
			}
			output {
				color: #fff;
				font-family: @ff-headline;
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.25em;
			}
			select {
				option {
					background-color: #83847a;
				}
			}
			::-webkit-input-placeholder {
			   color: #fff;
			}

			:-moz-placeholder { /* Firefox 18- */
			   color: #fff;
			}

			::-moz-placeholder {  /* Firefox 19+ */
			   color: #fff;
			}

			:-ms-input-placeholder {  
			   color: #fff;
			}
			& + .form-item {
				margin-left: 60px;
				@media(max-width: @screen-xs-max) {
					margin-left: 0;
					margin-top: 15px;
				}
			}
			.form-item {
				@media(max-width: @screen-xs-max) {
					width: 100%;
				}
			}
		}
		.form-type-select {
			padding: 0;
			&.form-item-d-unit {
				margin: 0 !important;
			}
		}
	}
	.views-submit-button {
		float: right;
		padding: 0;
		@media(max-width: @screen-xs-max) {
			float: none;
		}
		.form-submit {
			margin: 0 !important;
			padding: 40px;
			background-color: @brand-primary;
			border: 0;
			border-radius: 0;
			color: #fff;
			font-size: 1rem;
			line-height: 1.25em;
			white-space: nowrap;
			@media(max-width: @screen-xs-max) {
				display: block;
				padding: 20px;
				width: 100%;
			}
			&:hover {
				background-color: darken(@brand-primary, 15%);
			}
		}
	}
}

// Mosquee
.mosquee {
	margin: 8px 0;
	padding: 0 8px;
	@media(max-width: @screen-xs-max) {
		padding: 0;
	}
	.wrapper {
		background-color: #fff;
	}
	.content {
		padding: 30px;
		height: 280px;
		color: #bbbcad;
		line-height: normal;
		overflow: hidden;
		@media(max-width: @screen-md-max) {
			padding: 30px 20px;
			height: 340px;
		}
		@media(max-width: @screen-sm-max) {
			height: 280px;
		}
		@media(max-width: @screen-xs-max) {
			padding: 30px 15px;
			height: auto;
		}
		h3 {
			margin: 0 0 15px;
			color: #76776d;
			font-size: 1.5rem;
			line-height: 1em;
		}
		ul {
			.customList;
			margin: 0;
			color: @brand-primary;
			li {
				padding-left: 15px;
				&:before {
					top: 3px;
				}
				a {
					text-decoration: underline;
					&:hover {
						text-decoration: none;
					}
				}
			}
			& + p {
				margin-top: 10px;
			}
		}
	}
	.link {
		padding: 20px 30px;
		height: 140px;
		background-color: #dddac9;
		color: #76776d;
		font-family: @ff-headline;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1em;

		/* These are technically the same, but use both */
		overflow-wrap: break-word;
		word-wrap: break-word;

		-ms-word-break: break-all;
		/* This is the dangerous one in WebKit, as it breaks things wherever */
		word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;

		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
		@media(max-width: @screen-md-max) {
			padding: 20px 20px;
		}
		@media(max-width: @screen-xs-max) {
			padding: 20px 15px;
			height: auto;
		}
		p {
			margin: 0 0 5px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		#get_itineraire {
			display: inline-block;
			margin-top: 5px;
			color: #76776d;
			font-size: 1.13rem;
			font-weight: bold;
			@media(max-width: @screen-xs-max) {
				margin-top: 10px;
			}
			&:hover {
				color: @brand-primary;
				text-decoration: none;
			}
		}
	}
}

body.page-user, body.page-search404 {
	#main {
		.content-body;
	}
}


.gm-style-iw {
	> div {
		display: block !important;
		overflow: visible !important;
		max-width: none !important;
		> div {
			overflow: visible !important;
		}
	}
	.balloon {
		a {
			font-size: 1.13rem;
		}
	}
}

.date-pdetails {
	color: @brand-primary;
	font-size: 0.88rem;
	font-weight: 700;
}

#newslettermodal {
	.url-textfield {
		display: none;
	}
}

.item {
	&--galerie {
		display: block;
		position: relative;
		margin: 15px 0;
		.img-wrapper {
			position: relative;
			overflow: hidden;
			img {
				.transition(all .35s);
			}
			&:after {
			    content: '';
			    position: absolute;
			    z-index: 0;
			    top: 40%;
			    right: 0;
			    bottom: 0;
			    left: 0;
			    background: -moz-linear-gradient(top,rgba(17,16,10,0) 0,#11100a 100%);
			    background: -webkit-linear-gradient(top,rgba(17,16,10,0) 0,#11100a 100%);
			    background: linear-gradient(to bottom,rgba(17,16,10,0) 0,#11100a 100%);
			    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0011100a', endColorstr='#11100a', GradientType=0);
			    opacity: .7;
			}
		}
		.content {
			position: absolute;
			z-index: 5;
			bottom: 0;
			left: 0;
			padding: 15px;
			width: 100%;
			h3 {
				margin: 0;
				color: #fff;
			}
		}
		&:hover {
			.img-wrapper {
				img {
					-webkit-transform: scale(1.1);
					-moz-transform: scale(1.1);
					-ms-transform: scale(1.1);
					-o-transform: scale(1.1);
					transform: scale(1.1);
				}
			}
		}
	}
}

#swipebox-prev,
#swipebox-next,
#swipebox-close {
  background-image: url(../img/icons.png);
}
#swipebox-slider .slide-loading {
  background: url(../img/loader.gif) no-repeat center center;
}

.search-results {
	.search-info {
		display: none;
	}
}