.page-galerie-photos {
	.view-galerie-photos {
		padding-top: 90px;
		padding-bottom: 90px;
	}
}

.node-type-galerie-photos {
	.node-galerie-photos {
		padding-top: 60px;
		padding-bottom: 90px;
	}
	.gallerier-photos--back {
		margin-bottom: 30px;
	}
	.swipebox {
		display: block;
		margin: 15px 0;
	}
}