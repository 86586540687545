// Home
#service {
	margin-top: -200px;
	-webkit-animation-delay: .5s;
	-moz-animation-delay: .5s;
	animation-delay: .5s;
}

#cta_link {
	padding-bottom: 65px;
	.annuaire {
		float: left;
		padding: 30px 55px;
		width: 60%;
		background-color: #83847a;
		@media(max-width: @screen-md-max) {
			padding: 34px 20px 35px;
			width: 50%;
		}
		@media(max-width: @screen-sm-max) {
			padding: 30px 50px;
			width: 100%;
			text-align: center;
		}
		@media(max-width: @screen-xs-max) {
			position: relative;
			padding: 30px 15px;
		}
		&.annuaire-lg {
			width: 80%;
			@media(max-width: @screen-md-max) {
				width: 75%;
			}
			@media(max-width: @screen-sm-max) {
				width: 100%;
			}
		}
		.img {
			display: inline-block;
			vertical-align: middle;
			@media(max-width: @screen-xs-max) {
				display: none;
			}
			svg {
				display: block;
				opacity: .3;
				@media(max-width: @screen-md-max) {
					width: 95px;
				}
			}
		}
		.text {
			display: inline-block;
			vertical-align: middle;
			padding: 0 25px 0 55px;
			@media(max-width: @screen-md-max) {
				padding: 0 0 0 20px;
			}
			@media(max-width: @screen-sm-max) {
				padding: 0 0 0 50px;
				text-align: left;
			}
			@media(max-width: @screen-xs-max) {
				position: relative;
				z-index: 1;
				padding: 0;
			}
			h3 {
				margin: 0 0 20px;
				color: #fff;
			}
			.form {
				form {
					font-size: 0;
					width: 375px;
					max-width: 100%;
					@media(max-width: @screen-md-max) {
						width: 325px;
					}
					@media(max-width: @screen-sm-max) {
						width: 375px;
					}
					@media(max-width: @screen-sm-max) {
						width: 100%;
					}
					.form-item {
						display: inline-block;
						vertical-align: middle;
						margin: 0;
						padding: 0 20px;
						height: 50px;
						width: 50%;
						background-color: #fff;
						border: 0;
						border-radius: 4px 0 0 4px;
						color: #b1b2ac;
						font-family: @ff-headline;
						font-size: 1rem;
						font-style: italic;
						font-weight: 400;
						line-height: 1em;
						-webkit-box-shadow: none;
						-moz-box-shadow: none;
						box-shadow: none;
						@media(max-width: @screen-md-max) {
							padding: 0 10px;
							width: 40%;
						}
						@media(max-width: @screen-xs-max) {
							width: 100%;
							border-radius: 4px 4px 0 0;
						}
					}
					.form-submit {
						display: inline-block;
						vertical-align: middle;
						padding: 0 20px;
						height: 50px;
						width: 50%;
						background-color: lighten(@brand-primary, 10%);
						border: 0;
						border-radius: 0 4px 4px 0;
						font-size: 1rem;
						line-height: 1em;
						@media(max-width: @screen-md-max) {
							padding: 0 10px;
							width: 60%;
						}
						@media(max-width: @screen-xs-max) {
							width: 100%;
							border-radius: 0 0 4px 4px;
						}
						&:hover {
							background-color: darken(@brand-primary, 5%);
						}
					}
				}
			}
		}
	}
	.cta-hp {
		display: block;
		position: relative;
		float: left;
		padding-bottom: 20%;
		height: 0;
		width: 20%;
		background-color: #4d4e44;
		color: #fff;
		font-family: @ff-headline;
		font-weight: 400;
		font-size: 1.13rem;
		line-height: normal;
		text-align: center;
		text-decoration: none;
		@media(max-width: @screen-md-max) {
			padding-bottom: 25%;
			width: 25%;
		}
		@media(max-width: @screen-sm-max) {
			width: 50%;
		}
		@media(max-width: @screen-xs-max) {
			padding: 30px 15px;
			height: auto;
			width: 100%;
		}
		&.cta-hp-lg {
			@media(max-width: @screen-sm-max) {
				width: 100%;
			}
		}
		.wrapper {
			padding: 20px 30px;
			width: 100%;
			@media(max-width: @screen-xs-max) {
				position: relative;
				top: auto;
				left: auto;
				padding: 0;
				-webkit-transform: none;
			    -moz-transform: none;
			    -ms-transform: none;
			    -o-transform: none;
			    transform: none;
			}
		}
		.img {
			display: inline-block;
			margin-bottom: 20px;
			svg {
				display: block;
				opacity: .3;
				.transition(all .35s);
			}
		}
		span {
			display: block;
		}
		&.horaire {
			background-color: #707167;
			background-image: url(../img/pattern.png);
		}
		&:hover {
			.img {
				svg {
					opacity: 1;
				}
			}
		}
	}
}

#actualites {
	padding-top: 45px;
	padding-bottom: 60px;
	background-color: #fff;
	@media(max-width: @screen-xs-max) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	h2 {
		float: left;
		margin: 0;
		color: @brand-primary;
		@media(max-width: @screen-xs-max) {
			float: none;
		}
	}
	.navWrapper {
		float: right;
		@media(max-width: @screen-xs-max) {
			.clearfix;
			float: none;
			margin-top: 30px;
		}
		#customCarouselNav {
			float: right;
			@media(max-width: @screen-xs-max) {
				float: none;
			}
			.owl-prev,.owl-next {
				float: left;
				color: @brand-primary;
				font-size: 2.5rem;
				line-height: 1em;
				cursor: pointer;
				&:hover {
					color: darken(@brand-primary, 15%);
				}
			}
			.owl-next {
				margin-left: 50px;
			}
		}
		
	}
	.carouselWrapper {
		margin-top: 30px;
	}
}

#conseil {
	position: relative;
	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		overflow: hidden;
	}
	.theologien {
		position: relative;
		padding-top: 60px;
		padding-bottom: 90px;
		height: 500px;
		background-color: lighten(@brand-primary, 10%);
		border-bottom: 2px solid #f3f3f3;
		color: #fff;
		@media(max-width: @screen-sm-max) {
			height: auto;
		}
		@media(max-width: @screen-xs-max) {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		> * {
			max-width: 450px;
			@media(max-width: @screen-sm-max) {
				max-width: none;
			}
		}
		&:before {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			right: 100%;
			bottom: 0;
			width: 9999px;
			background-color: lighten(@brand-primary, 10%);
		}
		@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
			&:after {
				content: '';
				position: absolute;
				z-index: 0;
				top: 0;
				left: 100%;
				bottom: 0;
				width: 9999px;
				background-color: lighten(@brand-primary, 10%);
			}
		}
		h2 {
			margin: 0 0 15px;
    		min-height: 84px;
			color: #fff;
			font-size: 2.25rem;
			font-weight: 400;
			line-height: normal;
		}
		p {
			font-size: 1.13rem;
			line-height: 1.53em;
			font-style: italic;
		}
		a {
			color: #fff;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
		ul {
			.customList;
			margin-top: 80px;
			li {
				padding-left: 15px;
				&:before {
					color: #fff;
				}
				a {
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
				& +li {
					margin-top: 5px;
				}
			}
		}
	}
	.cib {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 50%;
		right: 0;
		height: 500px;
		background-color: #f3f3f3;
		@media(max-width: @screen-sm-max) {
			position: relative;
			top: auto;
			left: auto;
			right: auto;
			height: auto;
		}
		.conseil {
			padding-top: 60px;
			padding-bottom: 60px;
			height: 250px;
			background-color: fade(@brand-primary, 20%);
			color: @brand-primary;
			@media(max-width: @screen-md-max) {
				padding-top: 40px;
				padding-bottom: 38px;
			}
			@media(max-width: @screen-sm-max) {
				height: auto;
			}
			@media(max-width: @screen-xs-max) {
				padding-top: 30px;
				padding-bottom: 30px;
			}
			> * {
				max-width: 585px;
				@media(max-width: @screen-sm-max) {
					max-width: none;
				}
			}
			h2 {
				margin: 0 0 15px;
    			min-height: 84px;
				color: @brand-primary;
				font-size: 2.25rem;
				line-height: normal;
			}
			p {
				color: @brand-primary;
				font-size: 1.13rem;
				font-style: italic;
			}
			a {
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
			ul {
				.customList;
				margin-top: 80px;
				li {
					padding-left: 15px;
					&:before {
						color: @brand-primary;
					}
					a {
						text-decoration: none;
						&:hover {
							text-decoration: underline;
						}
					}
					& +li {
						margin-top: 5px;
					}
				}
			}
		}
		&.sameHeight {
			.clearfix;
			border-bottom: 2px solid #f3f3f3;
			.conseil {
				height: 100%;
				@media(max-width: @screen-md-max) {
					padding-top: 60px;
					padding-bottom: 60px;
				}
			}
		}
		.same-height--sm {
			clear: both;
		}
		.facebook {
			display: block;
			background-color: fade(@brand-primary, 5%);
			border-top: 2px solid #f3f3f3;
			padding: 60px 15px;
			height: 250px;
			color: #94a2be;
			font-family: @ff-headline;
			font-size: 1.13rem;
			font-weight: 400;
			line-height: normal;
			text-align: center;
			text-decoration: none;
			@media(max-width: @screen-sm-max) {
				height: auto;
			}
			@media(max-width: @screen-xs-max) {
				padding-top: 30px;
				padding-bottom: 30px;
			}
			.fa {
				display: block;
				margin-bottom: 5px;
				color: #3b5998;
				font-size: 4.13rem;
				line-height: 1em;
			}
			span {
				display: block;
				color: #3b5998;
				font-size: 2.25rem;
				font-weight: 700;
			}
		}
		.missions {
			position: relative;
			padding: 60px 15px;
			background-color: fade(@brand-primary, 20%);
			border-top: 2px solid #f3f3f3;
			border-left: 2px solid #f3f3f3;
			text-align: center;
			@media(max-width: @screen-xs-max) {
				padding-top: 30px;
				padding-bottom: 60px;
				border-left: 0;
			}
			&:after {
				content: '\f105';
				position: absolute;
				bottom: 50px;
				left: 50%;
				color: @brand-primary;
				font-family: 'FontAwesome';
				font-size: 2.19rem;
				line-height: 1em;
				-webkit-transform: translate(-50%,0);
				-moz-transform: translate(-50%,0);
				-ms-transform: translate(-50%,0);
				-o-transform: translate(-50%,0);
				transform: translate(-50%,0);
				@media(max-width: @screen-xs-max) {
					bottom: 30px;
				}
			}
			> * {
				margin-left: auto;
				margin-right: auto;
				max-width: 210px;
			}
			p {
				color: @brand-primary;
				font-family: @ff-headline;
				font-size: 1.5rem;
				line-height: 1.33em;
				a {
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

#newsletter {
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: #737469;
	background-image: url(../img/pattern.png);
	@media(max-width: @screen-xs-max) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	h2 {
		margin: 0;
		max-width: 360px;
		color: #fff;
		font-size: 1.63rem;
		line-height: normal;
	}
	.submit {
		text-align: right;
		@media(max-width: @screen-xs-max) {
			margin-top: 15px;
			text-align: left;
		}
	}
	.btn {
		padding: 18px 75px;
		font-size: 1.31rem;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
		@media(max-width: @screen-xs-max) {
			padding-left: 45px;
			padding-right: 45px;
		}
	}
}