.webform-client-form {
	.form-item {
		margin: 15px 0;
		label {
			margin: 0 0 5px;
			color: #83847a;
			font-family: @ff-headline;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.25em;
		}
		.form-control, .form-select {
			height: 40px;
			width: 100%;
			padding: 5px 15px;
			background-color: #fff;
			border: 1px solid @brand-primary;
			border-radius: 4px;
			color: @brand-primary;
			font-family: @ff-headline;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.25em;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}
		output {
			color: @brand-primary;
			font-family: @ff-headline;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.25em;
		}
		::-webkit-input-placeholder {
		   color: #83847a;
		}

		:-moz-placeholder { /* Firefox 18- */
		   color: #83847a;
		}

		::-moz-placeholder {  /* Firefox 19+ */
		   color: #83847a;
		}

		:-ms-input-placeholder {  
		   color: #83847a;
		}
		&.webform-component-date {
			.form-type-select {
				display: inline-block;
				vertical-align: middle;
				margin: 0;
				width: auto;
				@media(max-width: @screen-xs-max) {
					display: block;
					width: 100%;
					& + .form-type-select {
						margin-top: 5px;
					}
				}
			}
			.webform-calendar {
				display: inline-block;
				vertical-align: middle;
			}
		}
		&.webform-component-file {
			.form-managed-file {
				.clearfix;
			}
			.form-file {
				float: left;
				width: auto;	
				border-radius: 4px 0 0 4px;
				@media(max-width: @screen-xs-max) {
					float: none;
					width: 100%;
					border-radius: 4px 4px 0 0;
				}
			}
			.form-submit {
				float: left;
				padding: 5px 30px;
				height: 40px;
				border-radius: 0 4px 4px 0;
				@media(max-width: @screen-xs-max) {
					float: none;
					width: 100%;
					border-radius: 0 0 4px 4px;
				}
			}
		}
		&.webform-component-time {
			.form-type-select {
				display: inline-block;
    			vertical-align: middle;
    			margin: 0;
				width: auto;
			}
			@media(max-width: @screen-xs-max) {
				.form-radios {
					display: block;
					margin-top: 5px;
				}
			}
		}
		&.form-type-radio, &.form-type-checkbox {
			margin: 0;
		}
	}
	select[multiple], select[size], textarea.form-control {
		height: auto !important;
	}
	.checkbox, .radio {
		label {
			padding: 0;
		}
		input {
			position: relative;
			margin: 0 5px 0 0;
		}
	}
}

body.node-type-webform {
	.node-webform {
		.content-body;
	}
}